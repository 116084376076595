<template>
	<div ref="wrap">
		<div
			v-for="(item, index) in items"
			:key="item.id"
			:class="[
				index > 0 ? 'pt-6' : null,
				index < items.length -1 ? 'pb-6' : null,
			]"
		>
			<div
				v-if="
					item.type === 'text_content_block' &&
						item.content
				"
				class="rte"
				v-html="item.content"
			/>
			<v-img
				v-else-if="
					item.type === 'image_block' &&
						item.image
				"
				:src="item.image.url"
				:alt="item.image.alt"
			/>
			<template
				v-else-if="
					item.type === 'video_block' &&
						item.video_link
				"
			>
				<CookieContent type="marketing">
					<div v-html="item.video_link" />
				</CookieContent>
			</template>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ContentBlocks',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},
	},
	mounted () {
		this.init()
	},
	updated () {
		this.init()
	},
	methods: {
		init () {
			if (typeof this.$refs.wrap == 'undefined') return

			// Force links to open in new window
			const links = this.$refs.wrap.querySelectorAll('.rte a')

			for (const item of links) {
				const href = item.getAttribute('href')

				if (!href) continue
				if (href.startsWith('tel:') || href.startsWith('mailto:') || href.startsWith('#')) continue

				item.setAttribute('target', '_blank')
			}
		},
	},
}
</script>
