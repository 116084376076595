var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrap"},_vm._l((_vm.items),function(item,index){return _c('div',{key:item.id,class:[
			index > 0 ? 'pt-6' : null,
			index < _vm.items.length -1 ? 'pb-6' : null ]},[(
				item.type === 'text_content_block' &&
					item.content
			)?_c('div',{staticClass:"rte",domProps:{"innerHTML":_vm._s(item.content)}}):(
				item.type === 'image_block' &&
					item.image
			)?_c('v-img',{attrs:{"src":item.image.url,"alt":item.image.alt}}):(
				item.type === 'video_block' &&
					item.video_link
			)?[_c('CookieContent',{attrs:{"type":"marketing"}},[_c('div',{domProps:{"innerHTML":_vm._s(item.video_link)}})])]:_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }