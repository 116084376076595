<i18n>
{
  "en": {
		"titles": {
			"invoiceDetails": "Invoice details",
			"invoiceRows": "Invoice rows"
		},
		"notifications": {
			"paymentInfo": "You can pay the open sum to the bank account FI2920011800160718. Please use the correct reference number.",
			"noInvoiceRows": "Invoice has no rows",
			"paymentCanceled": "Payment was canceled",
			"paymentErrorOccured": "An error occured during the payment"
		},
		"buttons": {
			"payInvoice": "Pay invoice"
		},
		"paymentDialog": {
			"proceedToPayment": "Proceed to payment",
			"instructions": "You will be redirected to Svea Payment service, where you can pay your invoice. Please don't close the browser after payment to make sure that your payment will be successfully saved.",
			"creatingPayment": "Creating payment",
			"checkingPayment": "Checking payment"
		}
	},
  "fi": {
		"titles": {
			"invoiceDetails": "Laskun tiedot",
			"invoiceRows": "Laskurivit"
		},
		"notifications": {
			"paymentInfo": "Voit maksaa avoinna olevan summan tilille FI2920011800160718. Käytäthan viitettä, kiitos.",
			"noInvoiceRows": "Laskulla ei ole rivejä",
			"paymentCanceled": "Maksu peruutettiin",
			"paymentErrorOccured": "Maksussa tapahtui virhe"
		},
		"buttons": {
			"payInvoice": "Maksa lasku"
		},
		"paymentDialog": {
			"proceedToPayment": "Siirry maksamaan",
			"instructions": "Laskun maksaminen tapahtuu Svea Paymentin maksupalvelussa. Älä sulje selainta laskun maksamisen jälkeen, jotta maksutapahtuma kirjautuu oikein.",
			"creatingPayment": "Luodaan maksutapahtumaa",
			"checkingPayment": "Tarkistetaan maksutapahtumaa"
		}
	}
}
</i18n>

<template>
	<div v-if="item">
		<v-container
			v-if="item._details"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('titles.invoiceDetails') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-alert
						v-if="item._is_paid === false"
						type="info"
					>
						{{ $i18n.t('notifications.paymentInfo') }}
					</v-alert>
					<FilterableList
						:items="item._details"
						:enable-click="(false)"
						:enable-search="false"
						:multiline="true"
						subtitle-src="label"
						title-src="value"
					/>
				</v-card-text>
			</v-card>
		</v-container>
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('titles.invoiceRows') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-data-table
						:headers="item._table_headers"
						:items="item._table_rows"
						:disable-sort="(true)"
						:disable-pagination="(true)"
						:hide-default-footer="(true)"
						:mobile-breakpoint="(400)"
						:no-data-text="$i18n.t('notifications.noInvoiceRows')"
					>
						<!-- eslint-disable -->
						<template #item.total="slotProps">
						<!-- eslint-enable -->
							<span v-html="slotProps.item.total" />
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Invoice',
	data: () => ({
		item: null,
		createPaymentDialogOpen: false,
		paymentFormData: {},
		paymentLoading: false,
		paymentLoadingText: null,
		checkPaymentDialogOpen: false,
	}),
	computed: {
		...mapState({
			items: state => state.user.invoice,
		}),
	},
	mounted () {
		this.loadItem()

		// Check payment status
		if (Object.keys(this.$route.query).length) {
			this.$nextTick(() => {
				this.$store.commit('setBackButtonHistoryMode', false)
			})

			if (this.$route.query.status === 'ok') {
				this.checkPayment(this.$route.query)
			} else if (this.$route.query.status === 'cancel') {
				this.$store.dispatch('setNotifyMessage', this.$i18n.t('notifications.paymentCanceled'))
			} else if (this.$route.query.status === 'error') {
				this.$store.dispatch('setNotifyError', this.$i18n.t('notifications.paymentErrorOccured'))
			}

			// Clear query string
			this.$router.replace({'query': null})
		}
	},
	methods: {
		// Find current page from the store
		loadItem () {
			this.item = this.items.find(item => {
				return item.id == this.$route.params.pagename
			})

			if (!this.item) {
				this.$router.replace({ name: 'error404' })
			}
		},

		// Create a new payment and redirect user to Svea Payment
		createPayment () {
			this.paymentLoading = true

			// Define base URL for the current page
			const baseUrl = window.location.href.split(/\?|#/)[0]

			// Request payment data
			this.$api.Invoice.doRequest({
				method: 'POST',
				url: 'createpayment/',
				body: {
					invoice_id: this.item.id,
					pmt_okreturn: baseUrl + '?status=ok',
					pmt_errorreturn: baseUrl + '?status=error',
					pmt_cancelreturn: baseUrl + '?status=cancel',
					pmt_delayedpayreturn: baseUrl + '?status=cancel',
				},
			}).on('done', res => {
				if (!res.body.error) {
					// Populate payment form
					this.paymentFormData = Object.assign({}, res.body.paymentFormData)

					// Make sure that form is fully rendered and then post it
					this.$nextTick(() => {
						if (this.$refs.paymentForm) {
							this.$refs.paymentForm.submit()
						}
					})
				} else {
					this.paymentLoading = false
				}
			}).on('fail', () => {
				this.paymentLoading = false
			})
		},

		// Check payment status
		checkPayment (data = {}) {
			this.checkPaymentDialogOpen = true

			this.$api.Invoice.doRequest({
				method: 'POST',
				url: 'checkpayment/',
				body: data,
			}).on('done', () => {
				// Reload invoice from the store to update the view
				this.loadItem()
			}).on('finish', () => {
				this.checkPaymentDialogOpen = false
			})
		},
	},
}
</script>

<style lang="scss">

.paymentForm {
	overflow: hidden;
	height: 0;
}

</style>
