<i18n>
{
	"en": {
		"associationAssignments": {
			"title": "Association assignments"
		},
		"unionAssignments": {
			"title": "Union assignments"
		}
	},
	"fi": {
		"associationAssignments": {
			"title": "Yhdistystehtävät"
		},
		"unionAssignments": {
			"title": "Liittotehtävät"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="config._hero_image"
					class="white--text align-end"
					aspect-ratio="1.628"
					:src="config._hero_image.url"
					gradient="rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%"
					dark
				>
					<v-card-title class="headline">
						{{ user.firstname }} {{ user.lastname }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.entity_number"
						class="white--text"
					>
						{{ $i18n.t('general.memberNumber') }}: {{ user.entity_number }}
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{ user.firstname }} {{ user.lastname }}
					</v-card-title>
					<v-card-subtitle v-if="user._membershipTitle">
						{{ user._membershipTitle }}
					</v-card-subtitle>
				</template>
				<v-card-text>
					<FilterableList
						:items="user.membership"
						title-src="_list_title"
						summary-src="_list_summary"
						icon-src="_icon"
						icon-color-src="_icon_color"
						:enable-click="false"
						:enable-search="false"
						:multiline="true"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- Association assignments -->
		<v-container
			v-if="(user.assignment || []).length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('associationAssignments.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="user.assignment"
						title-src="_list_title"
						summary-src="_list_summary"
						icon-src="_icon"
						icon-color-src="_icon_color"
						:enable-click="false"
						:enable-search="false"
						:multiline="true"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- Union assignments -->
		<v-container
			v-if="(user.liittotehtava || []).length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('unionAssignments.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="user.liittotehtava"
						title-src="_list_title"
						summary-src="_list_summary"
						icon-src="_icon"
						icon-color-src="_icon_color"
						:enable-click="false"
						:enable-search="false"
						:multiline="true"
					/>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState([
			'config',
			'user',
		]),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
