<i18n>
{
  "en": {
		"noItemsMessage": "No invoices to show."
	},
  "fi": {
		"noItemsMessage": "Laskuja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'invoiceContainer'">
			<v-container
				v-if="ready === false"
				class="container--narrow"
			>
				<Spinner />
			</v-container>
			<v-container
				v-else
				class="container--narrow"
			>
				<FilterableList
					v-if="items && items.length"
					:items="items"
					title-src="_list_title"
					summary-src="_list_summary"
					icon-src="_icon"
					icon-color-src="_icon_color"
					:enable-search="false"
					:multiline="true"
					@itemClick="(item) => $router.push({ name: 'invoice', params: { pagename: item.id } })"
				>
					<template #meta="slotProps">
						<v-chip
							v-if="slotProps.item._status && Object.keys(slotProps.item._status).length"
							:color="slotProps.item._status.color"
							outlined
							label
						>
							<v-icon
								v-if="slotProps.item._status.icon"
								left
							>
								{{ slotProps.item._status.icon }}
							</v-icon>

							{{ slotProps.item._status.text }}
						</v-chip>
					</template>
				</FilterableList>
				<v-alert
					v-else
					type="info"
				>
					{{ $i18n.t('noItemsMessage') }}
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'InvoiceContainer',
	data: () => ({
		ready: false,
	}),
	computed: {
		...mapState({
			items: state => state.user.invoice,
		}),
	},
	mounted () {
		this.$api.Me.doRequest().then(() => {
			this.ready = true
		})
	},
}
</script>
