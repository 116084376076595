<i18n>
{
	"en": {
		"website": "Website"
	},
	"fi": {
		"website": "Kotisivu"
	}
}
</i18n>

<template>
	<div v-if="item">
		<v-container class="container--narrow">
			<v-card>
				<v-card-text>
					<FilterableList
						:items="item._details"
						subtitle-src="label"
						title-src="value"
						:enable-click="false"
						:enable-search="false"
						:multiline="true"
					/>
				</v-card-text>
				<template v-if="item.c_www_jasenetu">
					<v-divider />
					<v-card-actions>
						<v-btn
							v-if="item.c_www_jasenetu"
							color="primary"
							:href="item.c_www_jasenetu"
							target="_blank"
							text
						>
							<v-icon left>
								mdi-home
							</v-icon>
							{{ $i18n.t('website') }}
						</v-btn>
					</v-card-actions>
				</template>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'News',
	data: () => ({
		item: null,
	}),
	computed: {
		...mapState({
			user: state => state.user,
		}),
	},
	mounted () {
		// Find current item from the store
		this.item = this.user._benefits.find(item => {
			return item.id == this.$route.params.id
		})

		if (!this.item) {
			this.$router.replace({ name: 'error404' })
		}

		// Set custom headline
		this.$nextTick(() => {
			this.$root.$emit('setHeadline', this.item.title)
		})
	},
}
</script>
