<template>
	<v-app-bar
		class="topBar"
		app
		scroll-off-screen
	>
		<v-container class="container--narrow">
			<v-row>
				<v-btn
					v-show="showBackButton"
					icon
					@click="goBack"
				>
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
				<v-spacer />
				<v-btn
					class="logo"
					icon
					@click="gotoHomeRoute"
				>
					<v-img
						height="38"
						width="38"
						src="@/assets/logo.png"
						contain
					/>
				</v-btn>
				<v-spacer />
				<v-btn
					icon
					@click="$emit('menu-button-click')"
				>
					<v-icon>mdi-menu</v-icon>
				</v-btn>
			</v-row>
		</v-container>
		<v-container
			v-if="headline"
			slot="extension"
			class="container--narrow"
		>
			<v-toolbar-title>
				{{ headline }}
			</v-toolbar-title>
		</v-container>
	</v-app-bar>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'TopBar',
	props: {
		headline: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
	},
	computed: {
		...mapState({
			backButtonHistoryMode: state => state.backButtonHistoryMode,
		}),
		showBackButton () {
			return this.$route.matched.length >= 2
		},
	},
	methods: {
		gotoHomeRoute () {
			const homeRoute = this.$router.options.routes.find(item => item.name == 'membershipCard')

			if (!homeRoute || this.$route.name == homeRoute.name) return

			this.$router.push({
				name: homeRoute.name,
			})
		},
		goBack () {
			if (this.backButtonHistoryMode === true) {
				history.back()
			} else {
				const parentRoute = this.$route.matched[this.$route.matched.length - 2]

				this.$router.replace(parentRoute)
			}
		},
	},
}
</script>

<style lang="scss" scoped>

	.logo {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

</style>
